// extracted by mini-css-extract-plugin
export var button = "index-module--button--urhgS";
export var buttons = "index-module--buttons--1EDc9";
export var card = "index-module--card--UQIrI";
export var cards = "index-module--cards--RUyE6";
export var cardsWrapper = "index-module--cardsWrapper--h4fzn";
export var contribute = "index-module--contribute--ob1Ci";
export var contributeButton = "index-module--contributeButton--qsNa7";
export var contributeButtton = "index-module--contributeButtton--AWtUH";
export var example = "index-module--example--ZjSrz";
export var examples = "index-module--examples--B87SP";
export var examplesHeading = "index-module--examplesHeading--ki4f5";
export var external = "index-module--external--Mzg8G";
export var externalLinks = "index-module--externalLinks--ZtJpF";
export var gettingStarted = "index-module--gettingStarted--TUswO";
export var gettingStartedMessage = "index-module--gettingStartedMessage--bF5yS";
export var hero = "index-module--hero--yZ8D2";
export var imgContainer = "index-module--imgContainer--m4A0r";
export var logo = "index-module--logo--ZcEEj";
export var main = "index-module--main---YNK5";
export var moreExamples = "index-module--moreExamples--Gvkpg";
export var participate = "index-module--participate--FEque";
export var participateButton = "index-module--participateButton--lp8Ah";
export var participateButtton = "index-module--participateButtton--tcmqJ";
export var partner = "index-module--partner--Xqvtq";
export var partners = "index-module--partners--QCDus";
export var partnersList = "index-module--partnersList--Vc7qS";
export var takePart = "index-module--takePart--XKSDS";